import React from "react"

import { Header, About as AboutContent } from "../components"

// store
import useStore from "../store"

import SEO from "./SEO"

export default function About() {
  const current = useStore(state => state.current)

  return (
    <div>
      <SEO />
      <Header />
      <AboutContent content={current.about} />
    </div>
  )
}
